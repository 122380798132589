export function $maskText(
	text?: string | number | undefined | null,
	mask: string = '*'
) {
	if (!text) {
		return '';
	}

	const maskedText = text.toString().replace(/./g, mask);
	return maskedText;
}
export function $toInitials(
	value: string | number,
	options?: { maxLength?: number }
): string {
	if (value === null || value === undefined) {
		return '';
	}

	const stringValue = value.toString();

	if (!stringValue.includes(' ')) {
		return stringValue.charAt(0).toUpperCase();
	}

	const names = stringValue.split(/\s+/);
	const initials = names.map((name) => name.charAt(0).toUpperCase()).join('');
	const maxLength = options?.maxLength ?? 2;

	return initials.slice(0, maxLength);
}

/**
 * Formats a number as Norwegian currency (NOK)
 * @param amount The number to format
 * @param options Intl.NumberFormatOptions
 * @returns Formatted currency string
 */
export function $formatCurrency(
	amount: number | string | null | undefined,
	options: Intl.NumberFormatOptions = {}
): string {
	const defaultOptions: Intl.NumberFormatOptions = {
		style: 'currency',
		currency: 'NOK',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	};

	if (!amount) {
		return '';
	}

	const mergedOptions = { ...defaultOptions, ...options };
	const locale = mergedOptions.currency === 'NOK' ? 'nb-NO' : 'en-US';

	return new Intl.NumberFormat(locale, mergedOptions).format(Number(amount));
}
export function $textToColor(text: string): string {
	if (!text) {
		return 'bg-ax-custom-1 text-ax-custom-1-contrast';
	}

	function hashCode(str: string): number {
		let hash = 0;
		for (let i = 0; i < str.length; i++) {
			const char = str.charCodeAt(i);
			hash = (hash << 5) - hash + char;
			hash = hash & hash;
		}
		return Math.abs(hash);
	}

	const colorVariants = [
		'bg-ax-custom-1 text-ax-custom-1-contrast',
		'bg-ax-custom-2 text-ax-custom-2-contrast',
		'bg-ax-custom-3 text-ax-custom-3-contrast',
		'bg-ax-custom-4 text-ax-custom-4-contrast',
		'bg-ax-custom-5 text-ax-custom-5-contrast',
		'bg-ax-custom-6 text-ax-custom-6-contrast',
		'bg-ax-custom-7 text-ax-custom-7-contrast',
		'bg-ax-custom-8 text-ax-custom-8-contrast',
		'bg-ax-custom-9 text-ax-custom-9-contrast',
		'bg-ax-custom-10 text-ax-custom-10-contrast',
		'bg-ax-custom-11 text-ax-custom-11-contrast',
		'bg-ax-custom-12 text-ax-custom-12-contrast',
	];

	const colorIndex = hashCode(text) % colorVariants.length;
	return colorVariants[colorIndex];
}

export default function useTextUtils() {
	/**
	 * Converts a string to PascalCase
	 */
	function toPascalCase(text: string) {
		if (typeof text !== 'string') {
			return undefined;
		}

		return text?.charAt(0).toUpperCase() + text?.slice(1);
	}

	/**
	 * Converts a string to camelCase
	 */
	function toCamelCase(text: string) {
		if (typeof text !== 'string') {
			return undefined;
		}

		return text?.charAt(0).toLowerCase() + text?.slice(1);
	}

	/**
	 * Converts a name to initials
	 */
	function toInitials(firstName: string, lastName: string) {
		if (!firstName || !lastName) {
			return '';
		}

		const firstNames = firstName.split(/[\s-]/);
		const lastNames = lastName.split(/[\s-]/);

		const firstInitias = firstNames.map((name) => name.charAt(0));
		const lastInitials = lastNames.map((name) => name.charAt(0));

		const initials = `${firstInitias.join('')}${lastInitials.join('')}`;

		return initials.toUpperCase();
	}

	/**
	 * Converts a norwegian legal number to a formatted number
	 */
	function toFormattedLegalNo(number: string) {
		if (!number) {
			return '';
		}

		const formattedNumber = number.replace(
			/(\d{3})(\d{2})(\d{3})/,
			'$1 $2 $3'
		);

		return formattedNumber;
	}

	return {
		toPascalCase,
		toCamelCase,
		toInitials,
		toFormattedLegalNo,
	};
}
