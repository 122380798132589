import { AxosModules, Query } from '~/types/axos-api';
export default defineNuxtRouteMiddleware(async (to, from) => {
	const { IS_TEST } = useEnvironment();
	if (IS_TEST) {
		return;
	}

	if (to.path === '/') {
		const query = gql`
			query getModulesForMe {
				modulesForMe
			}
		`;
		const app = useNuxtApp();
		const res = await app.$graphql.request<Query>(query);
		const availableModules = res.modulesForMe;
		if (availableModules.includes(AxosModules.Account)) {
			return navigateTo('/accounts/list');
		}

		return navigateTo('/employees/list');
	}

	if (to.path === '/contacts' || to.path === '/contacts/') {
		return navigateTo('/contacts/list');
	}

	if (to.path === '/accounts' || to.path === '/accounts/') {
		return navigateTo('/accounts/list');
	}

	if (to.path === '/employees' || to.path === '/employees/') {
		return navigateTo('/employees/list');
	}

	if (to.path === '/account-meetings' || to.path === '/account-meetings/') {
		return navigateTo('/account-meetings/list');
	}
});
